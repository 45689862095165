<script>
export default {
  created () {
    const { link, uuid } = this.$route.params
    if (link && uuid) {
      this.$store.dispatch('user/VERIFICATIONEMAIL', {
        code: link,
        uuid,
      })
        .then(() => {
          this.$router.push({ path: '/auth/register/success' })
        })
        .catch(() => {
          this.$router.push({ path: '/auth/register/link-expired' })
        })
    } else {
      this.$router.push({ path: '/auth/register/link-expired' })
    }
  },
}
</script>
